import { axiosInstance } from "@/api";
import noAuthaxiosInstance from "@/api/noauth";
import { IAxiosApiResponse, IPasskey, ISecurityOptions } from "@/shared/types";
import { IWallet } from "@/shared/types/wallets";
import { CHANNELS } from "@/utils/consts";

class AuthService {
  loginRoute: string = "/auth/auth/sign-in";
  signUpRoute: string = "/auth/auth/sign-up";

  async login(data: any) {
    return this.send("sign-in", "POST", data);
  }

  async sendValidationToken(
    data: {
      identifier: string;
      gRecaptchaResponse: string;
    },
    type: string
  ) {
    if (type === CHANNELS.PHONE) {
      return this.sendValidation("phone", "POST", data);
    } else if (type === CHANNELS.EMAIL) {
      return this.sendValidation("email", "POST", data);
    }
  }

  async sendValidationUpdateToken(
    data: {
      identifier: string;
      gRecaptchaResponse: string;
    },
    type: string
  ) {
    if (type === CHANNELS.PHONE) {
      return this.sendValidationUpdate("phone", "POST", data);
    } else if (type === CHANNELS.EMAIL) {
      return this.sendValidationUpdate("email", "POST", data);
    }
  }

  async checkUser(data: any) {
    return this.send("check", "POST", data);
  }

  async signUp(data: any) {
    return this.send("sign-up", "POST", data);
  }

  async sendPasswordResetToken(data: any, type: any) {
    return this.send(`v/token/reset-password/send/${type}`, "POST", data);
  }

  async resetPassword(data: any) {
    return this.send("forgot-password/reset", "POST", data);
  }

  async changePassword(data: any) {
    return this.send("password-change", "POST", data);
  }

  async changeEmail(data: any) {
    return this.send("email-change", "POST", data);
  }

  async changePhone(data: any) {
    return this.send("phone-change", "POST", data);
  }

  async confirmChangeIdentifier(data: any) {
    return this.send(`v/confirm-change/${data}`, "GET");
  }

  async getEmailToken(data: any) {
    return this.send("v/token/transaction/send/email", "POST", data);
  }

  async getPhoneToken(data: any) {
    return this.send("v/token/transaction/send/phone", "POST", data);
  }

  async updateUsername(data: any) {
    return this.send("profile", "PUT", data);
  }

  async checkUsername(data: any) {
    return this.send("profile/check/username", "POST", data);
  }

  async getUserProfile() {
    return this.send("profile", "GET");
  }

  async getActiveSessions() {
    return this.send("profile/active-sessions", "GET");
  }

  async deleteActiveSession(data: string) {
    return this.send(`profile/active-session/${data}`, "DELETE");
  }

  async init2FA() {
    return this.send("v/2fa/init", "GET");
  }

  async activate2FA(token: any) {
    return this.send("v/2fa/activate", "POST", token);
  }

  async deactivate2FA(data: any) {
    return this.send("v/2fa/deactivate", "POST", data);
  }

  async getSecurityStatus() {
    const { data } = await this._send<{
      security: {
        data: {
          otp: {
            emailOtp: boolean;
            phoneOtp: boolean;
            google2FaToken: boolean;
          };
          passkey: {
            isActive: boolean;
          };
          preferred: string;
        };
        showPassKey: boolean;
        emailOtp: boolean;
        phoneOtp: boolean;
        google2FaToken: boolean;
      };
    }>("v/user/security-status", "GET");

    return data.data.security;
  }

  async getVerificationFlows() {
    return this.send("profile/kyc/available-flows", "GET");
  }

  async getKYCStatus() {
    return this.send("profile/kyc/status", "GET");
  }

  async sendKycExitEvent() {
    return this.send("kyc/internal/user-exited", "POST");
  }

  async deleteAccountInit() {
    return this.send("profile/delete-account/init", "POST");
  }

  async deleteAccountConfirm(data: any) {
    return this.send("profile/delete-account/confirm", "DELETE", data);
  }

  async getApiToken() {
    return this.send("business/token", "GET");
  }

  async generateToken() {
    return this.send("business/generate-token", "POST");
  }

  async createBusinessWebhook(data: object) {
    return this.send("business/create-webhook", "POST", data);
  }

  async changeUserPreferredAssetCurrency(data: { asset: IWallet["asset"] }) {
    return this.send("profile/preferred-asset", "PUT", data);
  }

  async verifySSO(data: object) {
    return this.send("verify-sso", "POST", data);
  }

  async registerSocials(data: object) {
    return this.send("sign-up-with-socials", "POST", data);
  }

  async loginSocials(data: object) {
    return this.send("sign-in-with-socials", "POST", data);
  }
  private async send(endpoint: string, method: string, data?: any) {
    return axiosInstance({
      url: `/auth-http/auth/${endpoint}`,
      method,
      data,
    });
  }

  private async _send<T>(
    endpoint: string,
    method: string,
    data?: T
  ): Promise<IAxiosApiResponse<T>> {
    return axiosInstance({
      url: `/auth-http/auth/${endpoint}`,
      method,
      data,
    });
  }

  private async sendValidation<T>(
    endpoint: string,
    method: string,
    data: any
  ): Promise<IAxiosApiResponse<T>> {
    return noAuthaxiosInstance({
      url: `/auth-http/auth/v/token/validation/send/${endpoint}`,
      method,
      data,
    });
  }

  private async sendValidationUpdate<T>(
    endpoint: string,
    method: string,
    data: any
  ): Promise<IAxiosApiResponse<T>> {
    return axiosInstance({
      url: `/auth-http/auth/v/token/change/${endpoint}`,
      method,
      data,
    });
  }

  // passkeys

  async getPassKeyCreationOptions() {
    const { data } = await this._send<{
      options: { publicKey: PublicKeyCredentialCreationOptions };
    }>("v/passkey/register/init", "GET");

    return data.data.options.publicKey;
  }

  async getPasskeyLoginOptions() {
    const { data } = await this._send<{
      options: { publicKey: PublicKeyCredentialRequestOptions };
    }>("sign-in/passkey/init", "GET");

    return data.data.options.publicKey;
  }

  async loginWithPassKey(data: any) {
    return this._send("sign-in/passkey", "POST", data);
  }

  async createPassKey(data: any) {
    return this._send<{}>("v/passkeys", "POST", data);
  }

  async getAllPassKeys() {
    const { data } = await this._send<{
      credentials: IPasskey[];
    }>("v/passkeys", "GET");

    return data.data.credentials;
  }

  async revokePasskey(id: string, security: Partial<ISecurityOptions>) {
    return this._send<{}>(`v/passkeys/${id}/revoke`, "DELETE", {
      security: security,
    });
  }

  async initReauthenticatePasskey() {
    const { data } = await this._send<{
      options: { publicKey: PublicKeyCredentialRequestOptions };
    }>("v/passkey/re-authenticate/init", "GET");

    return data.data.options.publicKey;
  }

  async reauthenticatePasskey(
    id: string
  ): Promise<IAxiosApiResponse<PublicKeyCredential>> {
    return this._send(`v/passkeys/${id}/revoke`, "GET");
  }
}

export default new AuthService();
